<template>
  <div class="approve">
    <van-nav-bar title="实名认证中心" left-arrow :safe-area-inset-top="true" fixed :border="false" @click-left="goBack"
      class="nav">
    </van-nav-bar>
    <div class="info-box">
      <van-image width="35px" height="20px" :src="require('../../assets/mine/icon_mine_realauth.png')" class="img-1" />
      <div class="title">根据国家相关法律法规要求，你需要填写身份证信息完成实名认证，以免影响使用相关功能或服务</div>
    </div>
    <div class="card-title">
      <p>身份证信息</p>
      <p>信息仅用于身份验证，我们将保障您的信息安全</p>
    </div>
    <div class="twoElements">
        <van-form @submit="onSubmit" >
          <van-cell-group inset class="form-items">
            <van-field   class="choosed-field" label-width="74px"  label-class="field-label" input-align="left" v-model="approve.realname" label="姓名" placeholder="真实姓名"/>
            <van-field    class="choosed-field" label-width="74px"  label-class="field-label"  input-align="left" v-model="approve.number" label="身份证号" type="text" placeholder="身份证号码" />
          </van-cell-group>
          <div class="submit-btn" @click="onSubmit">
              提交
          </div>
        </van-form>
    </div>


  </div>
</template>

<script>
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import Certification from "@/components/certification";
import TopBar from "@/components/topBar";
import axios from 'axios'
import {clearToast, setStore, showLoading, showSuccessToast} from "../../utils/util";
export default {
  name: "approve",
  setup() {
    const { proxy } = getCurrentInstance();
    const $router = useRouter();

    const state = reactive({
      listData: {},
      approve:{
        realname:"",
        number:""
      }
    });
    let productId = $router.currentRoute.value.params.productId
    onMounted(async () => {
    });
    const goBack = (() => {
      $router.back(-1);
    })
    const onSubmit = ((values) => {
      showLoading('认证中');
      proxy.$http.post("/wenwu-user/user/approve",state.approve).then(res => {
        clearToast()
        if (200 == res.data.code) {
          setStore("isValid",true)
          showSuccessToast('认证成功');

          setTimeout(()=>{
            $router.go(-1);
          },800);
        } else {
          proxy.$toast.fail(res.data.message);
        }
      }).catch(e=>{
        proxy.$toast.fail('认证失败');
      })
    })
    return {
      goBack,
      ...toRefs(state),
      productId,
      onSubmit
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>


